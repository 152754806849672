<template>
  <div class="loadingdiv">
    <!-- <div style="margin:0 auto;"> -->
    <img v-bind:src="loading_img" alt="请稍后...">
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading_img: '/loading1.gif'
    }
  },
  mounted() {
    //验证uid的有效性,如果有效跳到原页,无效跳到登录页
    this.Bus.ActionFunc('')
  }
}
</script>
<style lang="scss">
.loadingdiv {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    width: 500px;
  }
}
</style>